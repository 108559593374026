import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"
import Rodal from "rodal"
import { useMedia, useWindowSize } from "react-use"

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

  return { width: srcWidth * ratio, height: srcHeight * ratio }
}

const GalleryItemModal = ({ isOpen, onClose, children, width, height }) => {
  const isDesktopUp = useMedia("(min-width: 800px)")
  const size = useWindowSize()

  const mobileSize = calculateAspectRatioFit(
    width,
    height,
    size.width - 60,
    size.height - 60
  )

  return (
    <Rodal
      visible={isOpen}
      onClose={onClose}
      closeOnEsc={true}
      customStyles={{
        width: isDesktopUp ? width : mobileSize.width,
        height: isDesktopUp ? height : mobileSize.height,
      }}
      animaton="fade"
    >
      {children}
    </Rodal>
  )
}

const ArtGallery = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(0)

  return (
    <Layout page="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Original Handmade Art | By Izabelle Wilding</title>
        <meta name="Artwork by Izabelle Wilding" content="Helmet application" />
      </Helmet>
      <div className="w-full h-full relative m-auto " style={{ zIndex: 4000 }}>
        <div className="flex flex-wrap justify-center max-w-6xl m-auto sticky bg-white">
          {Object.entries(data).map(galleryItem => {
            const image = getImage(galleryItem[1])
            return (
              <>
                <div
                  className="square photo"
                  onClick={() => {
                    setIsOpen(true)
                    setSelectedImage(image)
                  }}
                >
                  <GatsbyImage
                    image={image}
                    className="img-item w-full h-full cursor-pointer"
                    style={{ position: "absolute" }}
                    alt="Painting by Izabelle Wilding"
                  />
                </div>
                <GalleryItemModal
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  closeOnEsc={true}
                  width={selectedImage.width}
                  height={selectedImage.height}
                >
                  <GatsbyImage
                    image={selectedImage}
                    className=" cursor-pointer m-auto"
                    alt="Painting by Izabelle Wilding"
                  />
                </GalleryItemModal>
              </>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const portraitImage = graphql`
  fragment portraitImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 590
        height: 792
        placeholder: DOMINANT_COLOR
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`

export const landscapeImage = graphql`
  fragment landscapeImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 900
        height: 700
        placeholder: DOMINANT_COLOR
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 590
        height: 590
        placeholder: DOMINANT_COLOR
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "image1.jpg" }) {
      ...landscapeImage
    }
    image2: file(relativePath: { eq: "image2.jpg" }) {
      ...squareImage
    }
    image3: file(relativePath: { eq: "image3.jpg" }) {
      ...portraitImage
    }
    image4: file(relativePath: { eq: "image4.jpg" }) {
      ...landscapeImage
    }
    image5: file(relativePath: { eq: "image5.jpg" }) {
      ...squareImage
    }
    image6: file(relativePath: { eq: "image6.jpg" }) {
      ...squareImage
    }
    image7: file(relativePath: { eq: "image7.jpg" }) {
      ...squareImage
    }
    image8: file(relativePath: { eq: "image8.jpg" }) {
      ...squareImage
    }
    image9: file(relativePath: { eq: "image9.jpg" }) {
      ...squareImage
    }
    image10: file(relativePath: { eq: "image10.jpg" }) {
      ...squareImage
    }
  }
`

export default ArtGallery
